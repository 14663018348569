import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { GQLComponent, GQLRichTextContent } from "../../types";

type ContactHeaderProps = { title: string; description: GQLComponent };

/*
 * title -> Title shown at the top of the contact form
 * description -> Description of the contact form
 */
const ContactHeader = (props: ContactHeaderProps): JSX.Element => {
  const DescCompContent = props.description.content as GQLRichTextContent;

  return (
    <Grid item xs={12}>
      <Typography variant="h6">{props.title}</Typography>
      {DescCompContent.html.map((node, index) => (
        <div
          key={index}
          className="MuiTypography-root MuiTypography-body2"
          dangerouslySetInnerHTML={{ __html: node }}
        />
      ))}
    </Grid>
  );
};

export default ContactHeader;
