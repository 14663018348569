import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { GQLComponent, GQLRichTextContent } from "../../types";

const UseStyles = makeStyles({
  header: {
    color: "white",
    backgroundColor: "#2B2B2B",
    padding: ".75rem 0 .75rem 2rem",
    width: "inherit",
    display: "flex",
  },

  contactInfo: {
    "& a": {
      color: "#AFBD21",
    },
  },
});

type ContactInfoProps = { title: string; component: GQLComponent };

/*
 * title -> Title of the resouce
 * component -> Content component of the resource
 */
const ContactInfo = (props: ContactInfoProps): JSX.Element => {
  const Classes = UseStyles();
  const ComponentContent = props.component.content as GQLRichTextContent;

  return (
    <Grid item xs={12} md={4}>
      <Typography variant="button" className={Classes.header}>
        {props.title}
      </Typography>
      {ComponentContent.html.map((node, index) => (
        <div
          key={index}
          className={`MuiTypography-root MuiTypography-body2 ${Classes.contactInfo}`}
          dangerouslySetInnerHTML={{ __html: node }}
        />
      ))}
    </Grid>
  );
};

export default ContactInfo;
