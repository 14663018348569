import React from "react";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb";
import ContactInfoComp from "../components/contact/contact-info";
import ContactForm from "../components/contact/contact-form";
import ContactHeader from "../components/contact/contact-header";
import InfoDialog from "../components/info-dialog";
import axios from "axios";
import GlobalConstants, { CloudBaseURL } from "../helpers/constants";
import { GetComponentByName } from "../helpers/query-data";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { graphql } from "gatsby";
import { PageProps } from "gatsby";
import { GQLQuery } from "../types";

type PageInputProps = { crystallize: GQLQuery };
type ContactProps = PageProps<PageInputProps>;

const Contact = (props: ContactProps): JSX.Element => {
  const QueryData = props.data.crystallize.catalogue;
  const PathObj = GetSimplePathObj(GlobalConstants.ContactUs);
  const ContactInfo = QueryData.children[0];
  const ContactInfoComponent = GetComponentByName(
    ContactInfo.components,
    GlobalConstants.Content
  );
  const DescriptionComponent = GetComponentByName(
    QueryData.components,
    GlobalConstants.Description
  );

  const [InfoDialogState, SetInfoDialogState] = React.useState({
    open: false,
    title: "",
    message: "",
  });

  /* Event handler for contact form close */
  const HandleCloseInfoDialog = (): void => {
    SetInfoDialogState((infoDialogState) => ({
      ...infoDialogState,
      open: false,
    }));
  };

  /* Event handler for contact form submit */
  const HandleFormSubmit = (event, state, resetState): void => {
    event.preventDefault();

    axios
      .post(CloudBaseURL + "/contact-us", state)
      .then(() => {
        resetState();
        SetInfoDialogState({
          open: true,
          title: GlobalConstants.RequestSent,
          message: GlobalConstants.RequestReceived,
        });
      })
      .catch(() => {
        SetInfoDialogState({
          open: true,
          title: GlobalConstants.RequestNotSent,
          message: GlobalConstants.RequestNotReceived,
        });
      });
  };

  return (
    <Layout currentPage="/contact">
      <Breadcrumb pathObj={PathObj} />
      <ContactHeader
        title={QueryData.name}
        description={DescriptionComponent}
      />
      <ContactInfoComp
        title={ContactInfo.name}
        component={ContactInfoComponent}
      />
      <ContactForm onSubmit={HandleFormSubmit} />
      <InfoDialog
        open={InfoDialogState.open}
        onClose={HandleCloseInfoDialog}
        title={InfoDialogState.title}
        message={InfoDialogState.message}
      />
    </Layout>
  );
};

export const Query = graphql`
  query {
    crystallize {
      catalogue(path: "/articles/contact-us-1") {
        ...CU_item
      }
    }
  }

  fragment CU_item on CRYSTALLIZE_Item {
    name
    components {
      ...CU_component
    }
    children {
      name
      components {
        ...CU_component
      }
    }
  }

  fragment CU_component on CRYSTALLIZE_Component {
    name
    content {
      ...CU_singleLine
      ...CU_richText
    }
  }

  fragment CU_singleLine on CRYSTALLIZE_SingleLineContent {
    text
  }

  fragment CU_richText on CRYSTALLIZE_RichTextContent {
    html
  }
`;

export default Contact;
