import React from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  makeStyles,
} from "@material-ui/core";

const UseStyles = makeStyles({
  header: {
    color: "white",
    backgroundColor: "#2B2B2B",
    padding: ".75rem 0 .75rem 2rem",
    width: "inherit",
    display: "flex",
  },

  formControl: {
    width: "100%",
  },

  textField: {
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
    boxSizing: "border-box",
    padding: ".5rem",

    "& .MuiInputLabel-root": {
      padding: ".5rem",
    },
  },

  textFieldSm: {
    width: "100%",

    "@media (min-width: 765px)": {
      width: "50%",
    },
  },

  textFieldLg: {
    width: "100%",
  },

  checkboxWrapper: {
    paddingRight: ".5rem",
  },

  checkbox: {
    padding: ".5rem 0 .5rem 0",
  },
});

type ContactFormProps = {
  onSubmit: (
    event: React.FormEvent,
    State: Record<string, unknown>,
    ResetState: () => void
  ) => void;
};

/*
 * onSubmit -> React event handler for the submit button
 */
const ContactForm = (props: ContactFormProps): JSX.Element => {
  const Classes = UseStyles();
  const InitialState = {
    name: "",
    email: "",
    company: "",
    asiNumber: "",
    businessPhone: "",
    cellPhone: "",
    faxPhone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    comment: "",
    mailingList: false,
  };

  const [State, SetState] = React.useState(InitialState);
  const ResetState = () => SetState(InitialState);

  /* Event handler for changes to form input fields*/
  const HandleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value, type } = event.target;

    let newValue = null;
    switch (type) {
      case "checkbox":
        newValue = checked;
        break;
      default:
        newValue = value;
        break;
    }

    SetState((State) => ({
      ...State,
      [name]: newValue,
    }));
  };

  return (
    <Grid item xs={12} md={8}>
      <Typography variant="button" className={Classes.header}>
        Contact Form
      </Typography>
      <form
        onSubmit={(event) => props.onSubmit(event, State, ResetState)}
        className={Classes.formControl}
      >
        <TextField
          required
          name="name"
          label="Name"
          value={State.name}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          required
          name="email"
          label="Email"
          value={State.email}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          required
          name="company"
          label="Company"
          value={State.company}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          required
          name="asiNumber"
          label="ASI/PPAI Number"
          value={State.asiNumber}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          required
          name="businessPhone"
          label="Business Phone"
          value={State.businessPhone}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          name="cellPhone"
          label="Cell Phone"
          value={State.cellPhone}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          name="faxPhone"
          label="Fax"
          value={State.faxPhone}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          required
          name="address1"
          label="Address 1"
          value={State.address1}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldLg}`}
        />
        <TextField
          name="address2"
          label="Address 2"
          value={State.address2}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldLg}`}
        />
        <TextField
          required
          name="city"
          label="City"
          value={State.city}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          required
          name="state"
          label="State"
          value={State.state}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          required
          name="zipCode"
          label="Zip Code"
          value={State.zipCode}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldSm}`}
        />
        <TextField
          multiline
          required
          name="comment"
          label="Comment"
          value={State.comment}
          onChange={HandleStateChange}
          className={`${Classes.textField} ${Classes.textFieldLg}`}
        />
        <div className={Classes.checkboxWrapper}>
          <FormControlLabel
            className={Classes.checkbox}
            control={
              <Checkbox
                name="mailingList"
                color="primary"
                checked={State.mailingList}
                onChange={HandleStateChange}
              />
            }
            label="I would like to subscribe to your mailing list."
          />
          <Button type="submit" color="primary" variant="contained">
            Send
          </Button>
        </div>
      </form>
    </Grid>
  );
};

export default ContactForm;
